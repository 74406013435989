import React from "react";
import DocumentPrescription from "./DocumentPrescription";

const DocumentConclusion = ({prescription, conclusion, appointment, message, islocked, user_type}) => {

    const renderCorrectionAndValidated = () => {
        return <>
            À la lecture du dossier, l’examen est rassurant.
            <br/><br/>
            <DocumentPrescription prescription={prescription} islocked={islocked} user_type={user_type}/>
        </>
    }

    const renderNoCorrectionAndValidated = () => {
        return "À la lecture du dossier, l’examen est rassurant."
    }

    const renderSkiacolAndValidated = () => {
        return "À la lecture du dossier, l’examen est rassurant.\n\nUne ordonnance de skiacol a été délivrée pour un examen complémentaire."
    }

    const conclusionText = () => {
        if (conclusion.conclusion_text) {
            return conclusion.conclusion_text
        } else if (message[0]?.message) {
            return message[0]?.message
        } else if (appointment.ophtalmologist_comment) {
            return appointment.ophtalmologist_comment
        } else {
            return "À la suite de l'analyse ophtalmologique, un nouvel examen est conseillé."
        }
    }

    const renderDenied = () => {

        return `${conclusionText()}\n\nAucune ordonnance n'a été délivrée.`
    }

    const hasSkiaCol = () => {
        return prescription.text.toLowerCase().includes("skiacol")
    }

    const hasCorrection = () => {
        return prescription.text && prescription.text.length > 0
    }

    const renderMessage = () => {
        switch (appointment.status_controle) {
            case 2:
                if (hasSkiaCol()) return renderSkiacolAndValidated()
                return hasCorrection ? renderCorrectionAndValidated() : renderNoCorrectionAndValidated()
            case 3:
                return renderDenied()
            default:
                return null
        }
    }

    return <>
        <h5 style={{marginBlock: 0}}>
            <b>Conclusion : </b>
        </h5>
        {appointment.status_controle >= 2 ? (
            <h5 style={{marginBottom: 20, marginTop: 3, whiteSpace: "pre-wrap"}}>
                {renderMessage()}
            </h5>
        ) : (
            <h5 style={{marginBottom: 20, marginTop: 3}}>
                L'examen n'a pas encore été contrôlé par un ophtalmologiste.
            </h5>
        )}
    </>
};

export default DocumentConclusion
